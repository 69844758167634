import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'

import PageTitle from '/components/common/PageTitle'
import { APIURL } from '/constants'
import services from '/components/services'
import DefaultLayout from '/components/layouts/DefaultLayout'
import MarketplaceDisplay from '/components/marketplace/MarketplaceDisplay'
import MarketplaceLoading from '/components/marketplace/MarketplaceLoading'
import { ComponentSelector } from '/components/theme/default/index'
import { withPageCustom } from '/components/utils'
import styles from '../../components/marketplace/Marketplace.module.scss'
import { pageRender } from '../../components/theme/default'

const Marketplace = ({ page, siteData }) => {
  const [type, setType] = useState('')
  const [plan, setPlan] = useState('')
  const { isLoading, data } = useQuery(
    ['products', type, plan],
    () =>
      services.get_v2(
        `${APIURL.API_MARKETPLACE_SEARCH}?type=${type}&plan=${plan}`
      ),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const title = `Marketplace | Benefits, Business Development, CRM`
  const description = `
    Explore our marketplace to find the best services for your business. We've
    done the heavy lifting for you to gain exclusive access to unique
    technology
  `
  if (page) {
    return (
      <div className={styles.content_padded_marketplace}>
        <PageTitle
          title={`${page.name || title || ''}`}
          description={`${page.meta_description || description || ''}`}
        />
        {isLoading && <MarketplaceLoading />}
        {data?.status === 'success' &&
          pageRender(page, {
            products: data.response,
            type,
            setType,
            plan,
            setPlan
          })}
      </div>
    )
  } else {
    return (
      <div className={styles.content_padded_marketplace}>
        <PageTitle
          title={`${title || ''}`}
          description={`${description || ''}`}
        />
        <ComponentSelector component="marketplace_hero" />
        {data?.status === 'success' && (
          <MarketplaceDisplay
            products={data.response}
            type={type}
            setType={setType}
            plan={plan}
            setPlan={setPlan}
          />
        )}
      </div>
    )
  }
}

Marketplace.propTypes = {
  product_data: PropTypes.object,
  page: PropTypes.object
}

Marketplace.Layout = DefaultLayout

export const getServerSideProps = withPageCustom(
  'marketplace',
  async (ctx, siteData, profile, page) => {
    return {
      props: {
        page,
        siteData
      }
    }
  }
)

export default Marketplace
